import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    TextInput,
    DialogBox,
    DialogBoxFooterType,
    NoLink,
    ZeroToInfiniteGrid,
    PicklistSelectInput,
} from "~/core";
import { withEditableGrid } from "~/hocs";
import { messages } from "./../i18n-messages";
import classnames from "classnames";
import { createAddLinkLabelText, createAddEditModalTitle } from "~/i18n-messages";

import { PICKLIST_SAMPLE_TYPE } from "~/core/picklist/picklist-names";

const PROPS_SAMPLE_TYPE_GUID = "sampleTypeGuid";
const PROPS_SAMPLE_TYPE_NAME = "sampleTypeName";
const PROPS_TEST_PACKAGE_ID = "testPackageId";
const PROPS_TEST_PACKAGE_GUID = "testPackageGuid";

class TestPackage extends Component {
    static propTypes = {
        classNames: PropTypes.string,
        record: PropTypes.array,
        formatMessage: PropTypes.func,
        renderDeleteModal: PropTypes.func,
        toggleModal: PropTypes.func,
        onChange: PropTypes.func,
        onAdd: PropTypes.func,
        onEdit: PropTypes.func,
        onDelete: PropTypes.func,
        onAddOrEditItem: PropTypes.func,
        deleteItem: PropTypes.func,
        isEditing: PropTypes.bool,
        isDeleting: PropTypes.bool,
        editData: PropTypes.object,
    };
    constructor(props) {
        super(props);
        this.state = {
            editingItem: false,
        };
    }

    onEdit = (record, index) => {
        const keysToUpdate = [
            PROPS_TEST_PACKAGE_ID,
            PROPS_SAMPLE_TYPE_GUID,
            PROPS_SAMPLE_TYPE_NAME,
            PROPS_TEST_PACKAGE_GUID,
        ];
        this.setState({ editingItem: true });
        this.props.onEdit({ record, index, keysToUpdate });
    };

    onAdd = () => {
        this.setState({ editingItem: false });
        this.props.onAdd();
    };

    renderAddEditModal = () => {
        const { formatMessage, isEditing, editData, onAddOrEditItem, onChange, toggleModal } =
            this.props;
        return (
            <DialogBox
                footerType={DialogBoxFooterType.ACTION_CANCEL}
                action="save"
                isOpen={isEditing}
                actionDisabled={
                    !editData[PROPS_TEST_PACKAGE_ID] || !editData[PROPS_SAMPLE_TYPE_GUID]
                }
                onAction={() => onAddOrEditItem()}
                onClose={() => toggleModal("isEditing", false)}
                title={createAddEditModalTitle(
                    this.state.editingItem,
                    formatMessage,
                    messages.testPackage
                )}
            >
                <PicklistSelectInput
                    picklistName={PICKLIST_SAMPLE_TYPE}
                    required
                    placeholderText={formatMessage(messages.sampleType)}
                    value={editData[PROPS_SAMPLE_TYPE_GUID]}
                    onChange={(value, displayValue) =>
                        onChange({
                            [PROPS_SAMPLE_TYPE_GUID]: value,
                            [PROPS_SAMPLE_TYPE_NAME]: displayValue,
                        })
                    }
                    optionListFilter={(item) =>
                        item.label === "Tissue" || item.label === "Soil" || item.label === "Water"
                    }
                />
                <TextInput
                    tabIndex={0}
                    maxLength={50}
                    placeholderText={formatMessage(messages.testPackage)}
                    labelText={formatMessage(messages.testPackage)}
                    value={editData[PROPS_TEST_PACKAGE_ID] || ""}
                    onChange={(value) => onChange({ [PROPS_TEST_PACKAGE_ID]: value })}
                    containerClassNames={["salesperson-textbox"]}
                    autoFocus
                    required
                />
            </DialogBox>
        );
    };

    render() {
        const { record, formatMessage, renderDeleteModal, onDelete } = this.props;
        const addLinkClassNames = classnames("add-link-container", this.props.classNames);
        return (
            <div className="form-section-child-stretch mini-grid">
                {record && record.length > 0 && (
                    <ZeroToInfiniteGrid
                        records={record}
                        columns={{
                            [PROPS_SAMPLE_TYPE_NAME]: {
                                title: formatMessage(messages.sampleType),
                            },
                            [PROPS_TEST_PACKAGE_ID]: {
                                title: formatMessage(messages.testPackage),
                            },
                        }}
                        className={"cell-stretch"}
                        onEdit={this.onEdit}
                        onDelete={(record, index) => onDelete({ record, index })}
                        showHeader={true}
                    />
                )}
                {this.renderAddEditModal()}
                {renderDeleteModal()}
                <div className={addLinkClassNames}>
                    <NoLink
                        className="add-link"
                        label={createAddLinkLabelText(formatMessage, messages.testPackage)}
                        onClick={this.onAdd}
                    ></NoLink>
                </div>
            </div>
        );
    }
}

export default withEditableGrid(TestPackage);
