import { batchActions } from "redux-batched-actions";
import {
    AllEffect,
    CallEffect,
    PutEffect,
    SelectEffect,
    TakeEffect,
    all,
    call,
    put,
    select,
    take,
    takeEvery,
    takeLatest,
} from "redux-saga/effects";

import * as blendingSelectors from "~/action-panel/components/common/product-blend-modal/selectors";
import * as blending from "~/action-panel/components/common/product-blend-modal/blending-utils";

import { setTriggeredPage } from "~/action-panel/components/event-module/actions";
import * as sampleActions from "~/action-panel/components/event-module/components/event-info/components/event-sample-soil/actions";
import * as sampleTissueActions from "~/action-panel/components/event-module/components/event-info/components/event-sample-tissue/actions";
import { getUserDepthConfig } from "~/action-panel/components/event-module/components/event-info/components/event-sample-soil/selectors";
import * as eventListSelectors from "~/action-panel/components/event-module/components/event-list/selectors";
import * as eventListActions from "~/action-panel/components/event-module/components/event-list/actions";
import * as eventInfoActions from "~/action-panel/components/event-module/components/event-info/actions";
import { picklistNames, selectors as picklistSelectors } from "~/core/picklist";
import { actions as cdActions, selectors as cdSelectors } from "~/customer-data";
import * as loginActions from "~/login/actions";
import { getTheUserGuid } from "~/login/selectors";
import { mapToolsActions } from "~/map";
import {
    setFieldsBackgroundOnly,
    setFieldsBackgroundOnlyBatch,
} from "~/map/components/map-control/actions";
import { actions as notificationActions } from "~/notifications";
import { AgEventAPI, FileImportAPI, LayerAPI, LayerUtilsAPI, APIErrorWithCode } from "@ai360/core";
import { Toolset, GeometryMath, GeometryUtils } from "@ai360/core";

import * as commonModels from "../model";
import * as recsEventsActions from "../actions";
import { getZonesState } from "../selectors";
import { getAreaListFromSurface, getFieldBoundaryPolygons } from "../sagas";

import { MergeableEventType } from "~/data/mergeable-events";

import * as models from "./model";
import * as actions from "./actions";
import * as selectors from "./selectors";
import { MSGTYPE } from "~/notifications/actions";
import { messages } from "../i18n-messages";
import { v4 as uuid } from "uuid";
import { logFirebaseEvent } from "~/utils/firebase";
import { StatusCodes } from "~/action-panel/components/common/status-messages";

type batchGuidMap = {
    count: number;
    newGuid: string;
};

const multiDepthIds = {
    1: "A",
    2: "B",
    3: "C",
    4: "D",
    5: "E",
    6: "F",
    7: "G",
    8: "H",
};

const getBatchGuidMap = (
    eventDetailsList: AgEventAPI.IEventDetails[]
): Map<string, batchGuidMap> => {
    const oldBatchGuidToNew = new Map();
    for (const details of eventDetailsList) {
        let eventChecked = false;
        details.eventAreaList.forEach((area) => {
            const originalBatchGuid = area.agEventList[0].agEventModel.batchSamplingEventGuid;
            if (!originalBatchGuid || eventChecked) {
                //only check each event once, to avoid multiple zones logging to the count property
                return;
            }
            const matchingGuid = oldBatchGuidToNew.get(originalBatchGuid);
            if (!matchingGuid) {
                oldBatchGuidToNew.set(originalBatchGuid, {
                    count: 1,
                    newGuid: uuid(),
                });
            } else {
                const match = oldBatchGuidToNew.get(originalBatchGuid);
                const newValue = {
                    count: (match.count += 1),
                    newGuid: match.newGuid,
                };
                oldBatchGuidToNew.set(originalBatchGuid, newValue);
            }
            eventChecked = true;
        });
    }
    return oldBatchGuidToNew;
};

const getBatchSamplingInfo = (
    agEvent: models.AgEvent,
    oldBatchGuidToNew: Map<string, batchGuidMap>
): models.AgEvent => {
    const hasBatchGuid = agEvent.agEventModel.batchSamplingEventGuid;
    const revisedProps: Partial<models.IAgEventModelMethods> = {
        agEventGuid: "",
    };
    if (hasBatchGuid) {
        const match = oldBatchGuidToNew.get(agEvent.agEventModel.batchSamplingEventGuid);
        const matchingGuid = match?.count > 1 ? match.newGuid : "";
        //if a match count is one or less, then the batchSamplingEventGuid should be cleared
        agEvent = models.AgEvent.prepareSamplingEvent(agEvent, matchingGuid);
        revisedProps.batchSamplingEventGuid = matchingGuid;
    } else {
        agEvent = models.AgEvent.prepareSamplingEvent(agEvent);
    }
    const newAgEventModel = agEvent.agEventModel.updateAgEventModel(revisedProps);
    agEvent = models.AgEvent.updateAgEvent(agEvent, {
        agEventGuid: "",
        agEventModel: newAgEventModel,
    });

    return agEvent;
};

const getSampleAgEventFromEventDetails = (eventDetails) => {
    console.assert(eventDetails.isSamplingEvent);
    console.assert(eventDetails.agEventTypeList.length === 1);
    const agEventTypeInfo = eventDetails.agEventTypeList[0];
    console.assert(eventDetails.eventAreaList.length > 0);
    const area = eventDetails.eventAreaList[0];
    console.assert(area.agEventList.length > 0);
    const { agEventModel } = area.agEventList[0];
    console.assert(agEventModel.sampleTypeGuid === agEventTypeInfo.sampleTypeGuid);

    return agEventModel;
};

const getScoutingPhotoGuids = (eventDetails) => {
    const guids = [];
    eventDetails.eventAreaList.forEach((area) => {
        area.agEventList.forEach((agEvent) => {
            if (agEvent.agEventModel.scoutingDetailList) {
                agEvent.agEventModel.scoutingDetailList.forEach((scoutingDetail) => {
                    scoutingDetail.photoList.forEach((photo) => {
                        guids.push(photo.eventScoutingDetailPhotoGuid);
                    });
                });
            }
        });
    });
    return guids;
};

const getScoutingCropPhotoGuids = (eventDetails) => {
    const guids = [];
    eventDetails.eventAreaList.forEach((area) => {
        area.agEventList.forEach((agEvent) => {
            if (agEvent.agEventModel.sitePhotoList) {
                agEvent.agEventModel.sitePhotoList.forEach((photo) => {
                    guids.push(photo.eventScoutingPhotoGuid);
                });
            }
        });
    });
    return guids;
};

const hasScoutingEvent = (eventDetailsList) => {
    return eventDetailsList.find((e) => e.isScoutingEvent) != null;
};

const filterScoutingEvent = (eventDetailsList) => {
    const revisedEventDetailsList = [];
    for (const eventDetail of eventDetailsList) {
        const revisedEventAreaList = eventDetail.eventAreaList
            .filter((area) => area.applyEventToArea)
            .map((a, i) => {
                const agEventList = a.agEventList.map((agEvent) => {
                    const scoutingDetailList = agEvent.agEventModel.scoutingDetailList.filter(
                        (sd) => {
                            return (
                                sd.growthStageGuid ||
                                sd.densityRatingGuid ||
                                sd.observationCount != null ||
                                sd.plantLocationGuid ||
                                sd.weedHeight != null ||
                                sd.trapID ||
                                (sd.photoList && sd.photoList.length > 0)
                            );
                        }
                    );
                    return {
                        ...agEvent,
                        agEventModel: {
                            ...agEvent.agEventModel,
                            scoutingDetailList,
                        },
                    };
                });
                return { ...a, agEventList, eventAreaId: i + 1 };
            });

        const revisedEventDetail = {
            ...eventDetail,
            eventAreaList: revisedEventAreaList,
        };
        revisedEventDetailsList.push(revisedEventDetail);
    }
    return revisedEventDetailsList;
};

export const fetchEventTypeInfo = function* (
    action: ReturnType<typeof loginActions.setUserInfo>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const userGuid = yield select(getTheUserGuid);
    try {
        const allTypesResponse = yield call(AgEventAPI.getAgEventTypeList, userGuid);
        const eventTypeInfoList = (allTypesResponse as any).map((jsonObj) =>
            models.AgEventTypeInfo.fromGetAgEventTypeResponseObj(jsonObj)
        );
        yield put(actions.setEventTypeInfo(eventTypeInfoList));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const getEventAreaListFromSurface = function* (
    action: ReturnType<typeof recsEventsActions.restoreAreaList>
): Generator<CallEffect | SelectEffect | PutEffect<any>, Readonly<models.AgEventArea>[], any> {
    const classbreakInfo = yield* getAreaListFromSurface(action);
    if (classbreakInfo == null) {
        return;
    }
    const [classIdToClassBreakMap, classIdToGeometryMap] = classbreakInfo;
    const filteredClassBreakMap = [...classIdToClassBreakMap].filter(
        ([classId, value]) => classIdToGeometryMap.has(classId) && value.acreage > 0
    );
    return filteredClassBreakMap.map(([classId, eventAreaClassBreak], idx) => {
        const geometry = classIdToGeometryMap.get(classId);
        console.assert(geometry != null);
        const polygons = GeometryMath.getSimplifiedPolygonParts(geometry).map(
            (poly) => new models.AgEventAreaPolygon(poly)
        );
        const calculatedArea = GeometryUtils.calculateArea(geometry);
        const area = new models.AgEventArea(polygons, [], calculatedArea);
        return models.AgEventArea.updateAgEventArea(area, {
            eventAreaId: idx + 1,
            eventAreaClassBreak,
        });
    });
};

export const onAddAggregateEvent = function* (
    action: ReturnType<typeof actions.addAggregateEvent>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { agEventTransactionTypeGuid, sampleTypeGuid } = action.payload;
    const { agEventTypeInfoList, fieldGuidToEventDetails } = yield select(selectors.getModuleState);

    const agEventTypeInfo = agEventTypeInfoList.find(
        (typeInfo) =>
            typeInfo.agEventTransactionTypeGuid === agEventTransactionTypeGuid &&
            typeInfo.sampleTypeGuid === sampleTypeGuid
    );
    console.assert(agEventTypeInfo != null);

    if (agEventTypeInfo.sampleType != null) {
        const fieldGuid = fieldGuidToEventDetails.keys().next().value;
        yield put(recsEventsActions.setSamplingEventId(fieldGuid));
    }
};

export const onApplyYieldCalibration = function* (
    action: ReturnType<typeof actions.applyYieldCalibration>
): Generator<
    | CallEffect
    | SelectEffect
    | PutEffect<{
          type: string;
          payload: {
              err: any;
              retryAction: any;
              intlMessageObj: any;
              intlMessageValues: any;
          };
      }>,
    void,
    unknown
> {
    const { yieldCalibrationModel } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        yield call(AgEventAPI.calibrateYield, userGuid, yieldCalibrationModel);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const onBatchSamplingReset = function* (): Generator<PutEffect<any>, void, any> {
    yield put(mapToolsActions.setBatchReset(true));
    yield put(actions.setSamplingFieldPointsPlaced(false));
};

export const onCopyBatchTemplateToEvents = function* (
    action: ReturnType<typeof actions.copyBatchTemplateToEvents>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const userGuid = yield select(getTheUserGuid);
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);

    const eventDetailsList = [...fieldGuidToEventDetails.values()]
        .filter((eventDetails) => eventDetails.fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID)
        .map((eventDetails) =>
            models.EventDetails.updateEventDetails(eventDetails, {
                startDate: `${eventDetails.momentStartDate.format("YYYY-MM-DD")}T20:00:00.000Z`,
                startTime: eventDetails.momentStartDate.format("h:mma"),
                endDate: `${eventDetails.momentEndDate.format("YYYY-MM-DD")}T20:00:00.000Z`,
                endTime: eventDetails.momentEndDate.format("h:mma"),
            })
        );

    try {
        yield call(AgEventAPI.validEventGeneral, userGuid, eventDetailsList);
    } catch (err) {
        if (!(err instanceof APIErrorWithCode)) {
            yield put(notificationActions.apiCallError(err, action));
        } else {
            yield put(actions.saveEventDetailsFailed(err.errorCodeList, err.model));
        }
        return;
    }

    yield put(recsEventsActions.setCurrentBatchFieldGuid(null));
    yield put(recsEventsActions.initializeEventsZones());
};

function getScoutingS3PhotoCopyMap(eventDetails) {
    const currentPhotoGuidToNewGuidMap = new Map();
    eventDetails.eventAreaList.forEach((area) => {
        area.agEventList.forEach((agEvent) => {
            if (eventDetails.isScoutingEvent && agEvent.agEventModel.scoutingDetailList) {
                agEvent.agEventModel.scoutingDetailList.forEach((scoutingDetail) => {
                    scoutingDetail.photoList.forEach((photo) => {
                        currentPhotoGuidToNewGuidMap.set(
                            photo.eventScoutingDetailPhotoGuid,
                            uuid()
                        );
                    });
                });
            }
        });
    });
    return currentPhotoGuidToNewGuidMap;
}

function* copyS3Photos(copyPhotoMap) {
    const userGuid = yield select(getTheUserGuid);
    const copyPhotoList = [];
    for (const [key, value] of copyPhotoMap) {
        copyPhotoList.push({
            existingPhotoGuid: key,
            newPhotoGuid: value,
        });
    }
    yield call(AgEventAPI.copyScoutingPhotos, userGuid, copyPhotoList);
    yield put(
        actions.cacheTemporaryScoutingPhotoList(
            copyPhotoList.map((p) => {
                return `${p.newPhotoGuid.toUpperCase()}.png`;
            })
        )
    );
}

function* getCopiedEventAreaList(
    eventDetails,
    areaIdToNewAreaIdPolygonMap,
    currentPhotoGuidToNewGuidMap = new Map()
) {
    const userGuid = yield select(getTheUserGuid);
    const eventId = yield call(AgEventAPI.getNextEventId, userGuid);

    const newEventAreaList = eventDetails.eventAreaList.map((area) => {
        const newAgEventList = area.agEventList.map((agEvent) => {
            const newAgEventModel = agEvent.agEventModel.updateAgEventModel({
                eventId,
                agEventGuid: "",
                productMixList: agEvent.agEventModel.productMixList?.map((x) => ({
                    ...x,
                    productMixGuid: "",
                    products: x.products.map((x) => ({
                        ...x,
                        productMixProductGuid: "",
                    })),
                    nutrients: x.nutrients.map((x) => ({
                        ...x,
                        productMixNutrientGuid: "",
                    })),
                })),
            });

            agEvent = models.AgEvent.updateAgEvent(agEvent, {
                agEventGuid: "",
                agEventModel: newAgEventModel,
            });
            if (agEvent.agEventModel instanceof models.SampleSetup) {
                agEvent = models.AgEvent.prepareSamplingEvent(agEvent);
            }
            if (eventDetails.isScoutingEvent && agEvent.agEventModel.scoutingDetailList) {
                agEvent.agEventModel.scoutingDetailList.forEach((scoutingDetail) => {
                    scoutingDetail.eventScoutingDetailGuid = "";
                    scoutingDetail.photoList.forEach(function (photo) {
                        photo.eventScoutingDetailPhotoGuid = currentPhotoGuidToNewGuidMap.get(
                            photo.eventScoutingDetailPhotoGuid
                        );
                    });
                });
            }
            return agEvent;
        });
        const { fieldBoundaryGuid, shape } = areaIdToNewAreaIdPolygonMap
            .get(area.eventAreaId)
            .get(area.eventAreaId);
        const zonePolygons = GeometryMath.getSimplifiedPolygonParts(shape).map(
            (poly) => new models.AgEventAreaPolygon(poly)
        );
        return models.AgEventArea.updateAgEventArea(area, {
            zonePolygons,
            eventAreaGuid: "",
            fieldBoundaryGuid,
            agEventGeneralGuid: "",
            agEventList: newAgEventList,
        });
    });

    return newEventAreaList;
}

function* getDefaultCroppingSeason(eventDetails) {
    try {
        const picklistOptionsCroppingSeason = yield select(
            picklistSelectors.getPicklistOptionsFromCode,
            picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
        );
        const curYearStr = String(new Date().getFullYear());
        const defaultOption = picklistOptionsCroppingSeason.find(
            (option) => option.label === curYearStr
        );
        return defaultOption.value;
    } catch (e) {
        return eventDetails.croppingSeasonGuid;
    }
}

export const onBatchCopyEvents = function* (
    action: ReturnType<typeof actions.batchCopyEvents>
): Generator<PutEffect<any>, void, any> {
    const { selectedEvents, fieldGuidList, fieldGuidToBoundaryGuidMap } = action.payload;
    const agEventGeneralGuidList = selectedEvents.map((e) => e.agEventGeneralGuid);
    const agEventTransactionTypeName = selectedEvents[0].agEventTypeName;

    yield put(
        actions.batchCopyEventDetails(
            agEventGeneralGuidList,
            agEventTransactionTypeName,
            fieldGuidList,
            fieldGuidToBoundaryGuidMap
        )
    );
};

export const onBatchCopyEventDetails = function* (
    action: ReturnType<typeof actions.batchCopyEventDetails>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const {
        agEventGeneralGuidList,
        agEventTransactionTypeName,
        fieldGuidList,
        fieldGuidToBoundaryGuidMap,
    } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    yield put(eventInfoActions.setEventDetailsLoading(true));

    try {
        const batchEventDetails = yield call(
            AgEventAPI.getBatchAgEvents,
            userGuid,
            agEventGeneralGuidList
        );
        const allEventDetails: models.EventDetails[] = [];
        const oldBatchGuidToNew = getBatchGuidMap(batchEventDetails);

        for (const details of batchEventDetails) {
            const eventDetails = models.EventDetails.fromJsonObj(details);
            const newEventAreaList = eventDetails.eventAreaList.map((area) => {
                const newAgEventList = area.agEventList.map((agEvent) => {
                    if (agEvent.agEventModel instanceof models.SampleSetup) {
                        agEvent = getBatchSamplingInfo(agEvent, oldBatchGuidToNew);
                    } else {
                        const newAgEventModel = agEvent.agEventModel.updateAgEventModel({
                            agEventGuid: "",
                        });
                        agEvent = models.AgEvent.updateAgEvent(agEvent, {
                            agEventGuid: "",
                            agEventModel: newAgEventModel,
                        });
                    }
                    return agEvent;
                });
                return models.AgEventArea.updateAgEventArea(area, {
                    agEventGeneralGuid: "",
                    agEventList: newAgEventList,
                    eventAreaGuid: "",
                    sourceEventAreaGuid: area.eventAreaGuid,
                    zonePolygons: null,
                });
            });
            const updatedEventDetails = models.EventDetails.updateEventDetails(eventDetails, {
                agEventGeneralGuid: "",
                eventAreaList: newEventAreaList,
            });
            allEventDetails.push(updatedEventDetails);
        }

        const fieldBoundaryGuid = fieldGuidToBoundaryGuidMap.get(
            commonModels.BATCH_TEMPLATE_FIELD_GUID
        );
        const batchDetail = Object.freeze(
            new models.EventDetails(
                commonModels.BATCH_TEMPLATE_FIELD_GUID,
                fieldBoundaryGuid,
                allEventDetails[0].agEventTypeList
            )
        );

        allEventDetails.splice(0, 0, batchDetail);

        yield put(actions.fetchBatchEventDetailsSucceeded(allEventDetails));
        yield put(
            actions.createNewCopyBatchEvent(
                fieldGuidList,
                fieldGuidToBoundaryGuidMap,
                agEventTransactionTypeName
            )
        );
        yield put(actions.setBatchCopySamplePoints());
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
    yield put(eventInfoActions.setEventDetailsLoading(false));
};

export const onCopyEventDetails = function* (
    action: ReturnType<typeof actions.copyEventDetails>
): Generator<
    | Generator<CallEffect | SelectEffect | PutEffect<any>, void, unknown>
    | CallEffect
    | SelectEffect
    | PutEffect<any>
    | TakeEffect,
    void,
    any
> {
    const fetchSamplingResults = false;
    const isCopyAction = true;
    yield put(
        actions.fetchEventDetails(
            action.payload.agEventGeneralGuid,
            fetchSamplingResults,
            isCopyAction
        )
    );
    const fetchSuccessfulAction = yield take(actions.FETCH_EVENT_DETAILS_SUCCEEDED);
    const { eventDetails } = fetchSuccessfulAction.payload;
    if (eventDetails.agEventGeneralGuid !== action.payload.agEventGeneralGuid) {
        return;
    }

    const updateAreaAction = yield take(recsEventsActions.UPDATE_EVENT_AREA_POLYGONS);
    const { areaIdToNewAreaIdPolygonMap } = updateAreaAction.payload;

    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    console.assert(fieldGuidToEventDetails.size === 1);

    // Reset `agEventGeneralGuid` and update the dates to the default for new events
    const fieldGuid = fieldGuidToEventDetails.keys().next().value;

    const copyPhotoMap = eventDetails.isScoutingEvent
        ? getScoutingS3PhotoCopyMap(eventDetails)
        : new Map();

    if (copyPhotoMap.size > 0) {
        yield copyS3Photos(copyPhotoMap);
    }

    const newEventAreaList = yield getCopiedEventAreaList(
        eventDetails,
        areaIdToNewAreaIdPolygonMap,
        copyPhotoMap
    );
    const croppingSeasonGuid = yield getDefaultCroppingSeason(eventDetails);
    const { agEventGeneralGuid, momentStartDate, momentEndDate } = new models.EventDetails(
        "",
        "",
        []
    );
    yield put(
        actions.updateEventDetails(fieldGuid, {
            agEventGeneralGuid,
            croppingSeasonGuid,
            momentStartDate,
            momentEndDate,
            eventAreaList: newEventAreaList,
        })
    );
    yield put(eventInfoActions.setEventDetailsLoading(false));
};

export const onCreateNewBatchEvent = function* (
    action: ReturnType<typeof actions.createNewBatchEvent>
): Generator<PutEffect<any>, void, any> {
    const {
        fieldGuidList,
        fieldGuidToBoundaryGuidMap,
        fieldGuidToCustomerGuidMap,
        agEventTransactionTypeName,
    } = action.payload;
    yield put(setFieldsBackgroundOnlyBatch(true));
    fieldGuidList.splice(0, 0, commonModels.BATCH_TEMPLATE_FIELD_GUID);
    yield put(
        actions.createNewEventDetails(
            fieldGuidList,
            fieldGuidToBoundaryGuidMap,
            fieldGuidToCustomerGuidMap,
            agEventTransactionTypeName
        )
    );
};

export const onCreateNewCopyBatchEvent = function* (
    action: ReturnType<typeof actions.createNewCopyBatchEvent>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuidList, fieldGuidToBoundaryGuidMap, agEventTransactionTypeName } =
        action.payload;
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    yield put(setFieldsBackgroundOnly(true));

    if (fieldGuidToEventDetails.entries().next().value[1].isSamplingEvent) {
        for (const fieldGuid of fieldGuidToEventDetails.keys()) {
            yield put(recsEventsActions.setSamplingEventId(fieldGuid));
        }
    } else {
        for (const [fieldGuid, eventDetails] of fieldGuidToEventDetails.entries()) {
            console.assert(eventDetails.eventAreaList.length >= 1);
            const { eventAreaId } = eventDetails.eventAreaList[0];
            yield put(recsEventsActions.setCurrentAreaId(fieldGuid, eventAreaId));
        }
    }
    fieldGuidList.splice(0, 0, commonModels.BATCH_TEMPLATE_FIELD_GUID);

    yield put(
        actions.createCopyBatchEventDetails(
            fieldGuidList,
            fieldGuidToBoundaryGuidMap,
            agEventTransactionTypeName
        )
    );
};

export const onCreateNewClassifiedEvent = function* (
    action:
        | ReturnType<typeof actions.createNewClassifiedEvent>
        | ReturnType<typeof recsEventsActions.restoreAreaList>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { field, agEventTransactionTypeName, triggeredPage } = (
        action as ReturnType<typeof actions.createNewClassifiedEvent>
    ).payload;

    if (triggeredPage) {
        yield put(setTriggeredPage(triggeredPage));
    }

    const eventAreaList = yield* getEventAreaListFromSurface(
        action as ReturnType<typeof recsEventsActions.restoreAreaList>
    );
    yield put(
        actions.createNewEventDetails(
            [field.fieldGuid],
            new Map([[field.fieldGuid, field.fieldBoundaryGuid]]),
            new Map([[field.fieldGuid, field.customerGuid]]),
            agEventTransactionTypeName,
            eventAreaList
        )
    );
};

export const onCreateNewEventDetails = function* (): Generator<
    SelectEffect | PutEffect<any>,
    void,
    any
> {
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    yield put(setFieldsBackgroundOnly(true));
    for (const [fieldGuid, eventDetails] of fieldGuidToEventDetails.entries()) {
        console.assert(eventDetails.eventAreaList.length >= 1);
        if (eventDetails.isSamplingEvent) {
            yield put(recsEventsActions.setSamplingEventId(fieldGuid));
        } else {
            const { eventAreaId } = eventDetails.eventAreaList[0];
            yield put(recsEventsActions.setCurrentAreaId(fieldGuid, eventAreaId));
        }
    }
};

export const onDeleteEvents = function* (
    action: ReturnType<typeof actions.deleteEvents>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { agEventGeneralGuidList } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        yield call(AgEventAPI.deactivateEvents, userGuid, agEventGeneralGuidList);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }
    yield put(actions.deleteEventsSuccessful());
};

export const onActivateEvent = function* (
    action: ReturnType<typeof actions.activateEvent>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { agEventGeneralGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);

    try {
        yield call(AgEventAPI.activateEvent, userGuid, agEventGeneralGuid);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }
    yield put(actions.activateEventSuccessful());
};

export const onFetchEventDetails = function* (
    action: ReturnType<typeof actions.fetchEventDetails>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { agEventGeneralGuid, fetchSamplingResults, isCopyAction } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        const response = yield call(AgEventAPI.getAgEvents, userGuid, agEventGeneralGuid);
        const eventDetails = models.EventDetails.fromJsonObj(response);

        if (isCopyAction) {
            const newEventAreaList = eventDetails.eventAreaList.map((area) => {
                const newAgEventList = area.agEventList.map((agEvent) => {
                    const newAgEventModel = agEvent.agEventModel.updateAgEventModel({
                        batchSamplingEventGuid: "",
                    });
                    if (agEvent.agEventModel instanceof models.SampleSetup) {
                        agEvent = models.AgEvent.prepareSamplingEvent(agEvent);
                    }
                    return models.AgEvent.updateAgEvent(agEvent, {
                        agEventGuid: "",
                        agEventModel: newAgEventModel,
                    });
                });
                return models.AgEventArea.updateAgEventArea(area, {
                    agEventList: newAgEventList,
                });
            });

            const updatedEventDetails = models.EventDetails.updateEventDetails(eventDetails, {
                eventAreaList: newEventAreaList,
            });

            yield put(
                actions.fetchEventDetailsSucceeded(
                    updatedEventDetails,
                    fetchSamplingResults,
                    isCopyAction
                )
            );
        } else {
            yield put(actions.fetchEventDetailsSucceeded(eventDetails, fetchSamplingResults));
        }
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const onFetchEventDetailsSucceeded = function* (
    action: ReturnType<typeof actions.fetchEventDetailsSucceeded>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { eventDetails, fetchSamplingResults } = action.payload;
    const eventAreaId = Math.min(
        ...eventDetails.eventAreaList.map((agEventArea) => agEventArea.eventAreaId)
    );
    yield put(recsEventsActions.setCurrentAreaId(eventDetails.fieldGuid, eventAreaId));

    const { mergeableEvents } = yield select(eventListSelectors.getModuleState);
    if (
        mergeableEvents &&
        mergeableEvents.type === MergeableEventType.Soil &&
        mergeableEvents.hasSoilSampleResults
    ) {
        yield put(recsEventsActions.fetchMergeSoilSampleResults(mergeableEvents));
        return;
    }
    const { fieldGuidToEventListMap } = yield select(selectors.getModuleState);
    const eventSummary = fieldGuidToEventListMap
        .get(eventDetails.fieldGuid)
        .find(
            (agEventSummary) =>
                agEventSummary.agEventGeneralGuid === eventDetails.agEventGeneralGuid
        );
    if (eventSummary != null && eventSummary.importedPoints > 0 && fetchSamplingResults) {
        const agEventModel = getSampleAgEventFromEventDetails(eventDetails);
        const isTissue = eventDetails.agEventTypeList.some(
            (agEventType) =>
                agEventType?.agEventTransactionTypeName === models.EVENT_TYPE_NAME_SAMPLING_TISSUE
        );
        if (isTissue) {
            yield put(recsEventsActions.fetchTissueSampleResults(agEventModel.agEventGuid));
        } else {
            yield put(recsEventsActions.fetchSoilSampleResults(agEventModel.agEventGuid));
        }
    }
    if (eventDetails.isSamplingEvent && eventSummary != null) {
        const agEventModel = getSampleAgEventFromEventDetails(eventDetails);
        const setProductivityAction = eventDetails.agEventTypeList.some(
            (agEventType) => agEventType.typeName === models.EVENT_TYPE_NAME_SAMPLING_TISSUE
        )
            ? sampleTissueActions.setShowProductivityRating
            : sampleActions.setShowProductivityRating;
        if (
            agEventModel.samplePoints.some(
                (point) =>
                    point.productivityRatingGuid != null && point.productivityRatingGuid !== ""
            )
        ) {
            yield put(setProductivityAction(true));
        } else {
            yield put(setProductivityAction(false));
        }
    }

    if (eventDetails.isScoutingEvent) {
        // Generate presigned urls for any Scouting photos and preload
        const scoutingPhotoGuids = getScoutingPhotoGuids(eventDetails);
        const scoutingCropPhotoGuids = getScoutingCropPhotoGuids(eventDetails);
        if (scoutingPhotoGuids.length > 0 || scoutingCropPhotoGuids.length > 0) {
            try {
                const photoGuids = scoutingPhotoGuids.concat(scoutingCropPhotoGuids);
                const result = yield call(
                    AgEventAPI.generateScoutingPhotoS3PreSignedUrls,
                    photoGuids
                );
                const presignedUrlMap = new Map(Object.entries(result));
                yield put(
                    recsEventsActions.setScoutingPhotoPresignedUrlMap(
                        presignedUrlMap as Map<string, string>
                    )
                );
                // Preload the images
                for (const url of presignedUrlMap.values()) {
                    new Image().src = url as string;
                }
            } catch {
                yield put(
                    notificationActions.pushToasterMessage(
                        messages.s3PhotoGeneratePresignedUrlsFailed,
                        MSGTYPE.ERROR
                    )
                );
            }
        }
    }
};

export const onFetchLayerNameToSurfaceInfoMap = function* (
    action: ReturnType<typeof actions.fetchLayerNameToSurfaceInfoMap>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid } = action.payload;

    let { layerNameToSurfaceInfoMap } = yield select(selectors.getModuleState);

    const userGuid = yield select(getTheUserGuid);
    const eventTypeNameToGuidMap = yield select(selectors.getEventTypeNameToTransactionGuidMap);

    let layerInfoList;
    try {
        layerInfoList = yield call(LayerAPI.getAvailableLayersByFieldGuid, userGuid, fieldGuid);
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }

    // FIXME: check that it has the same boundary guid
    // Keep Soil Type, EC Data, Yield Data, and Analysis Layers
    const transactionTypeGuids = new Set([
        eventTypeNameToGuidMap.get("EC Data"),
        eventTypeNameToGuidMap.get("Harvest"),
    ]);

    layerNameToSurfaceInfoMap = new Map();
    for (const layerInfo of layerInfoList) {
        const layerType = LayerUtilsAPI.getLayerType(layerInfo);
        if (
            layerType !== LayerUtilsAPI.LayerType.SOIL &&
            layerType !== LayerUtilsAPI.LayerType.ANALYSIS &&
            !(
                layerType === LayerUtilsAPI.LayerType.EVENT_IMPORTED &&
                transactionTypeGuids.has(layerInfo.agEventTransactionTypeGuid)
            )
        ) {
            continue;
        }

        let selectedSurfaceGuid = layerInfo.selectedSurfaceGuid;
        let selectedSurfaceTypeGuid = null;
        if (layerType === LayerUtilsAPI.LayerType.EVENT_IMPORTED) {
            // Need to find the polygon surface for the selected attribute
            const selectedAttributeGuid = layerInfo.selectedAttributeGuid;
            const polygonSurface = layerInfo.subLayers.find(
                (s) =>
                    s.importAttributeGuid === selectedAttributeGuid &&
                    s.surfaceType === "Event (Imported)"
            );
            if (polygonSurface == null) {
                continue;
            }
            selectedSurfaceGuid = polygonSurface.surfaceGuid;
            selectedSurfaceTypeGuid = polygonSurface.surfaceTypeGuid;
        }

        const surfaceInfo = LayerUtilsAPI.getSurfaceInfo(
            layerInfo,
            selectedSurfaceGuid,
            layerInfo.selectedAttributeGuid,
            selectedSurfaceTypeGuid
        );
        if (surfaceInfo == null) {
            continue;
        }

        const displayName =
            layerType === LayerUtilsAPI.LayerType.ANALYSIS
                ? `${surfaceInfo.displayName} - ${layerInfo.displayName}`
                : layerInfo.displayName;

        layerNameToSurfaceInfoMap.set(displayName, surfaceInfo);
    }

    yield put(actions.setLayerNameToSurfaceInfoMap(layerNameToSurfaceInfoMap));
};

export const onFetchSoilSampleResults = function* (
    action: ReturnType<typeof actions.fetchSoilSampleResults>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { agEventGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        const sampleResults = yield call(FileImportAPI.getSoilSampleResults, userGuid, agEventGuid);
        yield put(actions.fetchSoilSampleResultsSucceeded(sampleResults));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const onFetchTissueSampleResults = function* (
    action: ReturnType<typeof actions.fetchTissueSampleResults>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { agEventGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        const sampleResults = yield call(
            FileImportAPI.getTissueSampleResults,
            userGuid,
            agEventGuid
        );
        yield put(actions.fetchTissueSampleResultsSucceeded(sampleResults as any));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const onFetchMergeSoilSampleResults = function* (
    action: ReturnType<typeof actions.fetchMergeSoilSampleResults>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { mergeableEvents } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        const sampleResults = yield call(
            AgEventAPI.getMergeSoilSampleResults,
            userGuid,
            mergeableEvents.all
        );
        yield put(actions.fetchSoilSampleResultsSucceeded(sampleResults as any));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const onFetchYieldCalibration = function* (
    action: ReturnType<typeof actions.yieldCalibrationComplete>
): Generator<AllEffect | CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { agEventGuidList, windowLoad } = action.payload;
    //TODO: Add check to see if we need to fetch or already have the data in the map
    const userGuid = yield select(getTheUserGuid);
    try {
        const results = yield all([
            yield call(AgEventAPI.getHarvestLoadList, userGuid, {
                agEventGuidList,
                windowLoad,
            }),
            windowLoad ? yield call(AgEventAPI.getYieldCalibrationTypePicklist, userGuid) : null,
        ]);
        yield put(actions.fetchYieldCalibrationCompleted(results));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        yield put(actions.fetchYieldCalibrationCompleted(null));
    }
};

export const onRefreshYieldCalibration = function* (
    action: ReturnType<typeof actions.refreshYieldCalibration>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    yield put(actions.fetchYieldCalibration(action.payload[0], false));
};

export const onInitializeEventsZones = function* (
    action: ReturnType<typeof actions.initializeEventsZones>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);

    const eventDetailsList = [...fieldGuidToEventDetails.values()];
    let filteredEventDetailsList = eventDetailsList;

    const isBatchCopy = eventDetailsList.some((eventDetail) =>
        eventDetail.eventAreaList.some(
            (eventArea) =>
                eventArea.sourceEventAreaGuid != null && eventArea.sourceEventAreaGuid !== ""
        )
    );

    if (isBatchCopy) {
        //Skip field boundary assignment to event details that have copied zones
        filteredEventDetailsList = eventDetailsList.filter(
            (eventDetail) => eventDetail.eventAreaList.length === 1
        );
    }

    const fieldGuidList = filteredEventDetailsList
        .map((eventDetails) => {
            console.assert(eventDetails.eventAreaList.length === 1);
            console.assert(eventDetails.eventAreaList[0].zonePolygons == null);
            return eventDetails.fieldGuid;
        })
        .filter((fieldGuid) => fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID);

    const getAreaIdFromFieldGuid = (fieldGuid) => {
        const { eventAreaId } = fieldGuidToEventDetails.get(fieldGuid).eventAreaList[0];
        return eventAreaId;
    };
    let fieldGuidToAreaUpdateMap;
    try {
        fieldGuidToAreaUpdateMap = yield* getFieldBoundaryPolygons(
            fieldGuidList,
            getAreaIdFromFieldGuid
        );
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        return;
    }

    for (const [fieldGuid, areaIdToNewAreaIdPolygonMap] of fieldGuidToAreaUpdateMap.entries()) {
        yield put(actions.updateEventAreaPolygons(fieldGuid, areaIdToNewAreaIdPolygonMap));
    }
    yield put(actions.initializeEventsZonesSucceeded());
};

export const onInitializeEventsZonesSucceeded = function* () {
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const conversionFactors = yield select(blendingSelectors.getConversionFactors);
    const productBlendPicklists = yield select(blendingSelectors.getProductBlendPicklists);
    const props = { conversionFactors, productBlendPicklists };

    const eventsToUpdate: models.EventDetails[] = [...fieldGuidToEventDetails.values()]
        .filter((r) => r.fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID)
        .map((eventDetails) => {
            const newProps: Partial<models.EventDetails> = {};
            newProps.eventAreaList = eventDetails.eventAreaList.map(
                (eventArea: models.AgEventArea) => {
                    return models.AgEventArea.updateAgEventArea(eventArea, {
                        agEventList: eventArea.agEventList.map((event) => {
                            if (event?.agEventModel instanceof models.AgEventApplication) {
                                return models.AgEvent.updateAgEvent(event, {
                                    agEventModel: event.agEventModel.updateAgEventModel({
                                        productMixList: event.agEventModel.productMixList.map(
                                            (mix) => {
                                                return {
                                                    ...mix,
                                                    products: mix.products.map((product) => {
                                                        const isServiceProduct =
                                                            product.productParentType === "Service";
                                                        if (!isServiceProduct) {
                                                            return { ...product };
                                                        }

                                                        const serviceProductConversionFactor =
                                                            blending.getServiceProductCostConversionFactor(
                                                                blending.getPriceUnitFromRateUnit(
                                                                    blending.getRateUnit(
                                                                        mix.targetRateUnitGuid,
                                                                        props
                                                                    ),
                                                                    props
                                                                )?.value,
                                                                product.costUnitGuid,
                                                                mix.density,
                                                                props
                                                            );

                                                        const totalServiceProduct =
                                                            product.costUnit === "ac"
                                                                ? eventArea.calculatedArea
                                                                : product.costUnit === "ea"
                                                                ? 1
                                                                : +mix.targetRate *
                                                                  eventArea.calculatedArea *
                                                                  serviceProductConversionFactor;

                                                        return {
                                                            ...product,
                                                            rate: totalServiceProduct,
                                                            totalProduct: totalServiceProduct,
                                                        };
                                                    }),
                                                };
                                            }
                                        ),
                                    }),
                                });
                            }
                            return event;
                        }),
                    });
                }
            );
            return models.EventDetails.updateEventDetails(eventDetails, newProps);
        });

    for (const eventDetails of eventsToUpdate) {
        yield put(
            actions.updateEventDetails(eventDetails.fieldGuid, {
                eventAreaList: eventDetails.eventAreaList,
            })
        );
    }
};

const retrieveDefaultDepthConfig = function* () {
    const retrieved = yield select(getUserDepthConfig);
    if (retrieved) {
        return retrieved;
    }

    yield put(sampleActions.fetchSampleSoilEventDefaults());
    yield take(sampleActions.SET_USER_DEPTH_CONFIG);
    return yield select(getUserDepthConfig);
};

export const onDeleteTemporaryScoutingPhotos = function* (): Generator<
    CallEffect | SelectEffect | PutEffect<any>,
    void,
    any
> {
    const { temporaryScoutingPhotoList } = yield select(selectors.getModuleState);
    if (temporaryScoutingPhotoList.length > 0) {
        yield call(AgEventAPI.deleteScoutingPhotos, temporaryScoutingPhotoList);
        yield put(recsEventsActions.clearTemporaryScoutingPhotoList());
    }
};

const onMergeApplicationEvents = function* (action) {
    yield put(eventListActions.setEventPanelLoading(true));
    const { mergeableEvents } = action.payload;

    const eventGeneralGuids = mergeableEvents.all.map((event) => event.agEventGeneralGuid);

    const model = {
        eventGeneralGuids,
    };

    const userGuid = yield select(getTheUserGuid);
    yield call(AgEventAPI.mergeApplicationAgEvents, userGuid, model);
};

const onMergeSoilEvents = function* (action) {
    const { mergeableEvents } = action.payload;

    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const mergeIntoDetails = fieldGuidToEventDetails.get(mergeableEvents.mergeInto.fieldGuid);

    const defaultDepthConfig = yield retrieveDefaultDepthConfig();
    // Update the times strings to match the format expected for upload
    const mergeIntoAgEventGeneral = models.EventDetails.groomEventDetailsForAPICall(
        models.EventDetails.updateEventDetails(mergeIntoDetails, {
            startDate: `${mergeIntoDetails.momentStartDate.format("YYYY-MM-DD")}T20:00:00.000Z`,
            startTime: mergeIntoDetails.momentStartDate.format("h:mma"),
            endDate: `${mergeIntoDetails.momentEndDate.format("YYYY-MM-DD")}T20:00:00.000Z`,
            endTime: mergeIntoDetails.momentEndDate.format("h:mma"),
        }),
        defaultDepthConfig
    );

    const model = {
        agEventGeneralList: [mergeIntoAgEventGeneral],
        mergeEventGuids: mergeableEvents.collapsing.map((event) => event.agEventGeneralGuid),
    };

    const userGuid = yield select(getTheUserGuid);
    yield call(AgEventAPI.mergeAgEvents, userGuid, model);
    yield put(eventInfoActions.setEventDetailsLoading(false));
    yield put(eventInfoActions.closeEventInfo());
};

const mergeableEventsTypeToApiCall = (type) => {
    switch (type) {
        case MergeableEventType.Application:
            logFirebaseEvent("merge_events_application");
            return onMergeApplicationEvents;
        case MergeableEventType.Soil:
            logFirebaseEvent("merge_events_sampling");
            return onMergeSoilEvents;
        default:
            throw new Error("Unknown mergeable event type.");
    }
};

export const onCalibrationRollbackWarning = function* (): Generator<PutEffect<any>, void, any> {
    yield put(
        notificationActions.pushToasterMessage(
            messages.calibrationRollbackWarning,
            MSGTYPE.ERROR,
            undefined,
            false
        )
    );
};

// return on this function fickle, running as any for now
export const onMergeEventsSave = function* (
    action: ReturnType<typeof actions.mergeEventsSave>
): any {
    const { mergeableEvents } = action.payload;

    try {
        const apiCall = mergeableEventsTypeToApiCall(mergeableEvents.type);
        yield apiCall(action);
        yield put(actions.mergeEventsSucceeded(mergeableEvents));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
        yield put(actions.mergeEventsFailed());
    }
};

export const onMergeEventsFailed = function* (): Generator<
    CallEffect | SelectEffect | PutEffect<any>,
    void,
    any
> {
    yield put(eventListActions.setMergeableEvents(null));
    yield put(eventListActions.setEventPanelLoading(false));
};

export const onResetCurrentAgEventAreaAgEvent = function* (
    action: ReturnType<typeof actions.resetCurrentAgEventAreaAgEvent>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid, agEventTransactionTypeGuid } = action.payload;
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const { fieldGuidToCurrentAreaId } = yield select(getZonesState);
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    const currentAreaId = fieldGuidToCurrentAreaId.get(fieldGuid);
    const agEventArea = eventDetails.eventAreaList.find(
        (agEventArea) => agEventArea.eventAreaId === currentAreaId
    );
    const agEvent = agEventArea.agEventList.find(
        (agEvent) => agEvent.agEventTransactionTypeGuid === agEventTransactionTypeGuid
    );
    const newAgEvent = models.AgEvent.resetEventModel(agEvent);
    yield put(
        actions.updateCurrentAgEventAreaAgEventModel(
            fieldGuid,
            agEventTransactionTypeGuid,
            newAgEvent.agEventModel
        )
    );
};

export const onSetBatchCopySamplePoints = function* (): Generator<
    CallEffect | SelectEffect | PutEffect<any>,
    void,
    any
> {
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const fieldGuidList = [...fieldGuidToEventDetails.keys()].filter(
        (fieldGuid) => fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID
    );

    let allSamplePoints = [];
    let isTissue = false;
    for (const fieldGuid of fieldGuidList) {
        const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
        isTissue = eventDetails.agEventTypeList.some(
            (agEventType) =>
                agEventType?.agEventTransactionTypeName === models.EVENT_TYPE_NAME_SAMPLING_TISSUE
        );

        const agEventModel = getSampleAgEventFromEventDetails(eventDetails);
        allSamplePoints = allSamplePoints.concat(
            agEventModel.samplePoints.map((sp) => ({ ...sp, fieldGuid }))
        );
    }

    yield put(
        mapToolsActions.setActiveToolset(Toolset.SAMPLING_AUTO_PLACE, {
            fields: [{ fieldGuid: commonModels.BATCH_TEMPLATE_FIELD_GUID }],
            existingPoints: allSamplePoints,
            isTissue,
        })
    );
    yield put(actions.setSamplingFieldPointsPlaced(true));
};

export const onRestoreBatchSamplePoints = function* (): Generator<
    CallEffect | SelectEffect | PutEffect<any>,
    void,
    any
> {
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const fieldGuidList = [...fieldGuidToEventDetails.keys()].filter(
        (fieldGuid) => fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID
    );

    let allSamplePoints = [];
    let isTissue = false;
    for (const fieldGuid of fieldGuidList) {
        const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
        isTissue = eventDetails.agEventTypeList.some(
            (agEventType) =>
                agEventType.agEventTransactionTypeName === models.EVENT_TYPE_NAME_SAMPLING_TISSUE
        );

        const agEventModel = getSampleAgEventFromEventDetails(eventDetails);
        allSamplePoints = allSamplePoints.concat(
            agEventModel.samplePoints.map((sp) => ({ ...sp, fieldGuid }))
        );
    }
    yield put(
        mapToolsActions.setActiveToolset(Toolset.SAMPLING_AUTO_PLACE, {
            fields: [{ fieldGuid: commonModels.BATCH_TEMPLATE_FIELD_GUID }],
            existingPoints: allSamplePoints,
            isTissue,
        })
    );
};

export const onResetEventAreaPolygons = function* (
    action: ReturnType<typeof actions.resetEventAreaPolygons>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid } = action.payload;
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    const eventAreaId = Math.min(
        ...eventDetails.eventAreaList.map((agEventArea) => agEventArea.eventAreaId)
    );
    yield put(recsEventsActions.setCurrentAreaId(fieldGuid, eventAreaId));
};

export const onResetEventAreas = function* (
    action: ReturnType<typeof actions.resetEventAreas>
): Generator<CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid } = action.payload;
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);

    // We keep the properties from the first area that has `area.agEventList.length > 0`
    const { eventAreaId } = eventDetails.eventAreaList.find((area) => area.agEventList.length > 0);

    // Setting the polygon to `null` causes the zone tools to initialize the zone w/ the field boundary
    const areaIdToPolygonMap = new Map();
    for (const area of eventDetails.eventAreaList) {
        if (area.eventAreaId === eventAreaId) {
            areaIdToPolygonMap.set(eventAreaId, new Map([[eventAreaId, null]]));
        } else {
            areaIdToPolygonMap.set(area.eventAreaId, new Map());
        }
    }

    yield put(actions.updateEventAreaPolygons(fieldGuid, areaIdToPolygonMap));

    const newEventDetails = (yield select(selectors.getModuleState)).fieldGuidToEventDetails.get(
        fieldGuid
    );
    const field = {
        fieldGuid: newEventDetails.fieldGuid,
        fieldBoundaryGuid: newEventDetails.fieldBoundaryGuid,
        customerGuid: newEventDetails.customerGuid,
    };
    yield put(
        mapToolsActions.setActiveToolsetPayloadOnly({
            recEventDetails: newEventDetails,
            field,
        })
    );
};

export const onS3PhotoCopyFailed = function* (): Generator<PutEffect<any>, void, any> {
    yield put(notificationActions.pushToasterMessage(messages.s3PhotoCopyFailed, MSGTYPE.WARNING));
};

export const onS3PhotoUploadFailed = function* (): Generator<PutEffect<any>, void, any> {
    yield put(
        notificationActions.pushToasterMessage(messages.s3PhotoUploadFailed, MSGTYPE.WARNING)
    );
};

export const onSaveEventDetails = function* (
    action: ReturnType<typeof actions.saveEventDetails>
): Generator<any[] | boolean | TakeEffect | CallEffect | SelectEffect | PutEffect<any>, void, any> {
    const userGuid = yield select(getTheUserGuid);
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);

    let eventDetailsList = [...fieldGuidToEventDetails.values()].filter(
        (eventDetails) => eventDetails.fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID
    );
    let defaultDepthConfig = yield select(getUserDepthConfig);
    const hasSamplingEvent = eventDetailsList.find((e) => e.isSamplingEvent) != null;
    if (defaultDepthConfig == null && hasSamplingEvent) {
        yield put(sampleActions.fetchSampleSoilEventDefaults());
        yield take(sampleActions.SET_USER_DEPTH_CONFIG);
        defaultDepthConfig = yield select(getUserDepthConfig);
    }
    if (yield hasScoutingEvent(eventDetailsList)) {
        eventDetailsList = yield filterScoutingEvent(eventDetailsList);
    }
    // Update the times strings to match the format expected for upload
    eventDetailsList = eventDetailsList.map((eventDetails) =>
        models.EventDetails.groomEventDetailsForAPICall(
            models.EventDetails.updateEventDetails(eventDetails, {
                startDate: `${eventDetails.momentStartDate.format("YYYY-MM-DD")}T20:00:00.000Z`,
                startTime: eventDetails.momentStartDate.format("h:mma"),
                endDate: `${eventDetails.momentEndDate.format("YYYY-MM-DD")}T20:00:00.000Z`,
                endTime: eventDetails.momentEndDate.format("h:mma"),
            }),
            defaultDepthConfig
        )
    );

    const isUpdate = eventDetailsList.length === 1 && eventDetailsList[0].agEventGeneralGuid !== "";

    const fieldGuidToCountMap = new Map();
    if (!isUpdate) {
        const fieldMap = yield select(cdSelectors.getFieldMap);
        const fieldInfoList = eventDetailsList
            .map((eventDetails) => fieldMap.get(eventDetails.fieldGuid))
            .filter((field) => field != null);
        for (const { fieldGuid, eventCount } of fieldInfoList) {
            fieldGuidToCountMap.set(fieldGuid, eventCount);
        }
    }

    if (hasSamplingEvent) {
        const samplingEvents = eventDetailsList.filter((e) => e.isSamplingEvent);
        const hasInvalidZoneSamplingEvent = samplingEvents.some((eventDetails) => {
            const { agEventModel } = eventDetails.eventAreaList[0].agEventList[0];
            return (
                agEventModel.interpolationTypeId === models.InterpolationType.ZONE_SAMPLING &&
                !eventDetails.hasOneSampleIdPerZone
            );
        });
        if (hasInvalidZoneSamplingEvent) {
            // Add error code 2076 (Zone sampling requires one sample id per zone) and return
            yield put(actions.saveEventDetailsFailed([2076]));
            return;
        }
    }

    try {
        yield call(
            isUpdate ? AgEventAPI.updateAgEvents : AgEventAPI.addAgEvents,
            userGuid,
            eventDetailsList
        );
    } catch (err) {
        if (!(err instanceof APIErrorWithCode)) {
            yield put(notificationActions.apiCallError(err, action));
        } else {
            if (eventDetailsList.length === 1) {
                //For cases other than batch, mark the process as failed
                yield put(actions.saveEventDetailsFailed(err.errorCodeList, err.model));
            } else {
                //batch session may have partially succeded, so show error message for failed Events
                yield put(
                    notificationActions.pushToasterMessage(
                        messages.batchEventsFailed,
                        MSGTYPE.WARNING
                    )
                );
            }
        }
        yield put(eventInfoActions.setEventDetailsLoading(false));
        if (eventDetailsList.length === 1) {
            //For cases other than batch, skip the rest of the process
            return;
        }
    } finally {
        yield put(eventInfoActions.setEventDetailsLoading(false));
    }

    if (!isUpdate) {
        yield put(
            batchActions(
                [...fieldGuidToCountMap.entries()].map(([fieldGuid, eventCount]) =>
                    cdActions.updateFieldEventCount(fieldGuid, eventCount + 1)
                )
            )
        );
    }
    yield put(actions.saveEventDetailsSucceeded());
};

const onSetEventSamplePoints = function* (action) {
    const { samplePoints } = action.payload;
    const { fieldGuidToEventDetails, soilSampleResults, tissueSampleResults } = yield select(
        selectors.getModuleState
    );

    if (fieldGuidToEventDetails.size > 1) {
        //handle batch case
        const fieldGuidList = [...fieldGuidToEventDetails.keys()].filter(
            (fieldGuid) => fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID
        );

        for (const fieldGuid of fieldGuidList) {
            const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
            const agEventModel = getSampleAgEventFromEventDetails(eventDetails);

            const newPointDefaultProps = {
                productivityRatingGuid: "",
                samplePointDepths:
                    agEventModel.samplePoints.length > 0 &&
                    agEventModel.samplePoints[0].samplePointDepths
                        ? agEventModel.samplePoints[0].samplePointDepths
                        : null,
            };

            const isTissue = agEventModel.samplePoints.some(
                (sampleItem) => sampleItem.eventSampleTissuePointGuid !== undefined
            );
            const guidProp = isTissue
                ? models.samplePointGuidProperty.TISSUE
                : models.samplePointGuidProperty.SOIL;
            const applicableSamplePoints = samplePoints.filter((pt) => pt.fieldGuid === fieldGuid);
            if (applicableSamplePoints.length < 1) {
                continue;
            }
            const pointGuidToPointMap = new Map(
                agEventModel.samplePoints.map((samplePoint) => [samplePoint[guidProp], samplePoint])
            );
            const newSamplePoints = applicableSamplePoints.map((samplePoint) => {
                const existingPoint: any = pointGuidToPointMap.has(samplePoint[guidProp])
                    ? pointGuidToPointMap.get(samplePoint[guidProp])
                    : newPointDefaultProps;
                return {
                    ...(existingPoint as any),
                    ...samplePoint,
                    samplePointDepths: existingPoint.samplePointDepths,
                    cropGrowthStageGuid: existingPoint.cropGrowthStageGuid,
                    plantPartGuid: existingPoint.plantPartGuid,
                };
            });
            yield put(
                actions.updateSamplingAgEventModel(fieldGuid, {
                    samplePoints: newSamplePoints,
                })
            );
        }
    } else {
        const fieldGuidToEventDetailsValue = fieldGuidToEventDetails.entries().next().value;
        if (fieldGuidToEventDetailsValue === undefined) {
            return;
        }
        const [fieldGuid, eventDetails] = fieldGuidToEventDetailsValue;

        const agEventModel = getSampleAgEventFromEventDetails(eventDetails);

        const newPointDefaultProps = {
            productivityRatingGuid: "",
            samplePointDepths:
                agEventModel.samplePoints.length > 0 &&
                agEventModel.samplePoints[0].samplePointDepths
                    ? agEventModel.samplePoints[0].samplePointDepths
                    : null,
        };

        const isTissue = agEventModel.samplePoints.some(
            (sampleItem) => sampleItem.eventSampleTissuePointGuid !== undefined
        );
        const guidProp = isTissue
            ? models.samplePointGuidProperty.TISSUE
            : models.samplePointGuidProperty.SOIL;

        const pointGuidToPointMap = new Map(
            agEventModel.samplePoints.map((samplePoint) => [samplePoint[guidProp], samplePoint])
        );
        const newSamplePoints = samplePoints.map((samplePoint) => {
            const existingPoint: any = pointGuidToPointMap.has(samplePoint[guidProp])
                ? pointGuidToPointMap.get(samplePoint[guidProp])
                : newPointDefaultProps;
            return {
                ...(existingPoint as any),
                ...samplePoint,
                samplePointDepths: existingPoint.samplePointDepths,
                cropGrowthStageGuid: existingPoint.cropGrowthStageGuid,
                plantPartGuid: existingPoint.plantPartGuid,
            };
        });
        if (soilSampleResults) {
            yield* updateSoilResults(newSamplePoints);
        } else if (tissueSampleResults) {
            yield* updateTissueResults(newSamplePoints);
        }
        yield put(
            actions.updateSamplingAgEventModel(fieldGuid, {
                samplePoints: newSamplePoints,
            })
        );
    }
};

function* updateSoilResults(samplePoints) {
    //Determine addition or subtraction of points
    const { soilSampleResults, soilSampleResultsOriginal } = yield select(selectors.getModuleState);
    let revisedSampleResults = {
        gridHeader: [...soilSampleResults.gridHeader],
        gridData: [...soilSampleResults.gridData],
    };
    let totalDepths = 0;
    samplePoints.forEach((p) => {
        const depthAdd = p.samplePointDepths ? p.samplePointDepths?.length : 0;
        totalDepths += depthAdd;
    });
    if (soilSampleResults?.gridHeader.length > totalDepths + 1) {
        //sample point was removed
        revisedSampleResults = yield updateSampleResult(soilSampleResults, samplePoints, false);
    } else if (
        soilSampleResults?.gridHeader.length < totalDepths + 1 &&
        soilSampleResultsOriginal
    ) {
        //sample point was added
        revisedSampleResults = yield addToSampleResults(
            soilSampleResults,
            soilSampleResultsOriginal,
            samplePoints,
            revisedSampleResults
        );
    }
    yield put(actions.updateSoilSampleResults(revisedSampleResults));
}

function* updateTissueResults(samplePoints) {
    //Determine addition or subtraction of points
    const { tissueSampleResults, tissueSampleResultsOriginal } = yield select(
        selectors.getModuleState
    );
    let revisedSampleResults = {
        gridHeader: [...tissueSampleResults.gridHeader],
        gridData: [...tissueSampleResults.gridData],
    };
    if (tissueSampleResults?.gridHeader.length > samplePoints.length + 1) {
        //sample point with results was removed
        revisedSampleResults = yield updateSampleResult(tissueSampleResults, samplePoints, true);
    } else if (
        tissueSampleResults?.gridHeader.length < samplePoints.length + 1 &&
        tissueSampleResultsOriginal
    ) {
        //sample point was added
        revisedSampleResults = yield addToSampleResults(
            tissueSampleResults,
            tissueSampleResultsOriginal,
            samplePoints,
            revisedSampleResults
        );
    }
    yield put(actions.updateTissueSampleResults(revisedSampleResults));
}

const addToSampleResults = (
    sampleResults,
    sampleResultsOriginal,
    samplePoints,
    revisedSampleResults
) => {
    //Determine type of add, then point to appropriate add function
    const columnNames = sampleResults.gridHeader.map((p) => p.columnName.replace(/[^\d-]/g, ""));
    const isComposite = columnNames[1]?.includes("-");
    const totalDepths = samplePoints[0]?.samplePointDepths?.length;
    const isMultiDepth = totalDepths > 1;
    const newPoint = samplePoints.find((p) => {
        const pointAttribute = isComposite
            ? p.sampleId.toString() + "-" + p.sequenceId.toString()
            : p.sampleId.toString();
        return !columnNames.includes(pointAttribute);
    });
    const applicableId = isComposite
        ? newPoint.sampleId.toString() + "-" + newPoint.sequenceId.toString()
        : newPoint.sampleId.toString();
    if (newPoint.isNew) {
        //new point was added
        revisedSampleResults = isMultiDepth
            ? addMultiDepthSampleResult(sampleResults, applicableId, totalDepths)
            : addSampleResult(sampleResults, applicableId);
    } else {
        //point with results was re-added through Undo
        revisedSampleResults = isMultiDepth
            ? reAddMultiDepthSampleResult(
                  sampleResults,
                  applicableId,
                  totalDepths,
                  sampleResultsOriginal
              )
            : reAddSampleResult(sampleResults, applicableId, sampleResultsOriginal);
    }
    return revisedSampleResults;
};

const addSampleResult = (sampleResults, applicableId) => {
    //Sort through and add appropriate results to each grid data item
    const revisedGridHeader = [
        ...sampleResults.gridHeader,
        {
            columnName: applicableId,
            hasResults: false,
            hasMatch: false,
        },
    ];
    const revisedGridData = [];
    for (const attribute of sampleResults.gridData) {
        const revisedValues = [];
        for (const value of attribute.sampleResultValues) {
            revisedValues.push(value);
        }
        revisedValues.push({
            samplePointSequenceDepthId: applicableId,
            sampleResultValue: null,
        });
        const revisedAtt = {
            actualAttributeName: attribute.actualAttributeName,
            sampleAttributeName: attribute.sampleAttributeName,
            sampleResultValues: revisedValues,
        };
        revisedGridData.push(revisedAtt);
    }
    return sortGridHeader(revisedGridHeader, revisedGridData);
};

const reAddSampleResult = (sampleResults, applicableId, sampleResultsOriginal) => {
    //Add back a point that previously existed, along with the results data
    const matchingHeader = sampleResultsOriginal.gridHeader.find(
        (gh) => gh.columnName === applicableId
    );
    const revisedGridHeader = [...sampleResults.gridHeader, matchingHeader];
    const revisedGridData = [];
    for (const attribute of sampleResults.gridData) {
        const revisedValues = [];
        const originalAttribute = sampleResultsOriginal.gridData.find(
            (gd) => gd.sampleAttributeName === attribute.sampleAttributeName
        );
        const originalResult =
            originalAttribute.sampleResultValues.find(
                (a) => a.samplePointSequenceDepthId === applicableId
            ) || {};
        for (const value of attribute.sampleResultValues) {
            revisedValues.push(value);
        }
        revisedValues.push({
            samplePointSequenceDepthId: originalResult.samplePointSequenceDepthId,
            sampleResultValue: originalResult.sampleResultValue,
        });
        revisedValues.sort((a, b) =>
            new Intl.Collator("en", { numeric: true }).compare(
                a.samplePointSequenceDepthId,
                b.samplePointSequenceDepthId
            )
        );
        const revisedAtt = {
            actualAttributeName: attribute.actualAttributeName,
            sampleAttributeName: attribute.sampleAttributeName,
            sampleResultValues: revisedValues,
        };
        revisedGridData.push(revisedAtt);
    }
    return sortGridHeader(revisedGridHeader, revisedGridData);
};

const addMultiDepthSampleResult = (sampleResults, applicableId, totalDepths) => {
    //Add a multi-depth soil sample point, with appropriate number of results
    let idx = 0;
    const newGridHeaders = [];
    const isMultiDepth = totalDepths > 1;
    do {
        const columnName = isMultiDepth
            ? applicableId.toString() + multiDepthIds[idx + 1]
            : applicableId;
        newGridHeaders.push({
            columnName,
            hasResults: false,
            hasMatch: false,
        });
        idx++;
    } while (idx < totalDepths);

    const revisedGridHeader = [...sampleResults.gridHeader, ...newGridHeaders];
    const revisedGridData = [];
    for (const attribute of sampleResults.gridData) {
        const revisedValues = [];
        for (const value of attribute.sampleResultValues) {
            revisedValues.push(value);
        }
        idx = 0;
        do {
            const applicableDepthId = isMultiDepth
                ? applicableId.toString() + multiDepthIds[idx + 1]
                : applicableId;

            revisedValues.push({
                samplePointSequenceDepthId: applicableDepthId,
                sampleResultValue: null,
            });
            idx++;
        } while (idx < totalDepths);

        const revisedAtt = {
            actualAttributeName: attribute.actualAttributeName,
            sampleAttributeName: attribute.sampleAttributeName,
            sampleResultValues: revisedValues,
        };
        revisedGridData.push(revisedAtt);
    }
    return sortGridHeader(revisedGridHeader, revisedGridData);
};

const reAddMultiDepthSampleResult = (
    sampleResults,
    applicableId,
    totalDepths,
    sampleResultsOriginal
) => {
    //Add back a point that previously existed, with appropriate number of results
    let revisedSampleResults = sampleResults;
    let idx = 0;
    do {
        const multiDepthId = applicableId.toString() + multiDepthIds[idx + 1];
        revisedSampleResults = reAddSampleResult(
            revisedSampleResults,
            multiDepthId,
            sampleResultsOriginal
        );
        idx++;
    } while (idx < totalDepths);
    return revisedSampleResults;
};

const sortGridHeader = (revisedGridHeader, revisedGridData) => {
    //Sort grid header based on depth ids or applicable sequence ids
    const firstGridHeader = revisedGridHeader.find((h) => h.columnName === "Sample Attribute");
    const remainingGridHeaders = revisedGridHeader
        .filter((h) => h.columnName !== "Sample Attribute")
        .sort((a, b) =>
            new Intl.Collator("en", { numeric: true }).compare(a.columnName, b.columnName)
        );
    const sortedGridHeader = [firstGridHeader, ...remainingGridHeaders];
    const revisedSampleResults = {
        gridData: revisedGridData,
        gridHeader: sortedGridHeader,
    };
    return revisedSampleResults;
};

const updateSampleResult = (sampleResults, samplePoints, isTissue) => {
    //Filter out any points that were removed, along with corresponding results
    const revisedGridHeader = sampleResults.gridHeader.filter((gh) => {
        const sp = samplePoints.some((p) => {
            const pointAttribute = gh.columnName.includes("-")
                ? p.sampleId.toString() + "-" + p.sequenceId.toString()
                : p.sampleId.toString();
            const columnPrefix = gh.columnName.replace(/[^\d-]/g, "");
            return pointAttribute === columnPrefix;
        });
        return sp || gh.columnName === "Sample Attribute";
    });
    const revisedGridData = [];
    for (const attribute of sampleResults.gridData) {
        const revisedValues = attribute.sampleResultValues.filter((v) => {
            return samplePoints.some((p) => {
                const pointAttribute = v.samplePointSequenceDepthId.includes("-")
                    ? p.sampleId.toString() + "-" + p.sequenceId.toString()
                    : p.sampleId.toString();
                const ids = isTissue
                    ? [pointAttribute]
                    : p.samplePointDepths.map((d) => pointAttribute + d.depthId);
                return ids.includes(v.samplePointSequenceDepthId);
            });
        });
        const revisedAtt = {
            actualAttributeName: attribute.actualAttributeName,
            sampleAttributeName: attribute.sampleAttributeName,
            sampleResultValues: revisedValues,
        };
        revisedGridData.push(revisedAtt);
    }
    const revisedSampleResults = {
        gridData: revisedGridData,
        gridHeader: revisedGridHeader,
    };
    return revisedSampleResults;
};

const onSetEventZonesFromLayer = function* (action) {
    const { fieldGuid } = action.payload;
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);

    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);

    // The way this is coded, this is currently only a supported operation for
    //  sample events, where `agEventList` is ignored except on the first zone.
    console.assert(eventDetails.eventAreaList.length > 0);
    console.assert(
        eventDetails.eventAreaList.every((agEventArea) => agEventArea.agEventList.length === 1)
    );
    console.assert(eventDetails.isSamplingEvent);

    const agEventList = eventDetails.eventAreaList[0].agEventList;

    const applyEventToArea = true; // default for sampling
    let eventAreaList = yield* getEventAreaListFromSurface({
        ...action,
        payload: {
            ...action.payload,
            field: {
                fieldGuid,
            },
        },
    });
    if (eventAreaList != null) {
        eventAreaList = eventAreaList.map((area, idx) => {
            const eventList = idx === 0 ? agEventList : [];
            return models.AgEventArea.updateAgEventArea(area, {
                agEventList: eventList,
                applyEventToArea,
            });
        });
        yield put(
            actions.updateEventDetails(fieldGuid, {
                eventAreaList,
            })
        );
    }

    const newEventDetails = (yield select(selectors.getModuleState)).fieldGuidToEventDetails.get(
        fieldGuid
    );
    const field = {
        fieldGuid: newEventDetails.fieldGuid,
        fieldBoundaryGuid: newEventDetails.fieldBoundaryGuid,
        customerGuid: newEventDetails.customerGuid,
    };
    yield put(
        mapToolsActions.setActiveToolsetPayloadOnly({
            recEventDetails: newEventDetails,
            field,
        })
    );
};

const onSetSamplingEventId = function* (action) {
    const { fieldGuid } = action.payload;
    const userGuid = yield select(getTheUserGuid);
    try {
        const eventId = yield call(AgEventAPI.getNextEventId, userGuid);
        yield put(actions.updateSamplingAgEventModel(fieldGuid, { eventId }));
    } catch (err) {
        yield put(notificationActions.apiCallError(err, action));
    }
};

export const onUpdateCurrentAgEventAreaAgEventModel = function* (
    action: ReturnType<typeof actions.updateCurrentAgEventAreaAgEventModel>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid, agEventTransactionTypeGuid, newProps } = action.payload;
    const { fieldGuidToCurrentAreaId } = yield select(getZonesState);
    const agEventAreaId = fieldGuidToCurrentAreaId.get(fieldGuid);
    yield put(
        actions.updateAgEventModel(fieldGuid, agEventAreaId, agEventTransactionTypeGuid, newProps)
    );
};

export const onUpdateEventModel = function* (
    action: ReturnType<typeof actions.updateAgEventModel>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid, agEventAreaId, agEventTransactionTypeGuid, newProps } = action.payload;
    yield put(
        actions.setEventModel(fieldGuid, agEventAreaId, agEventTransactionTypeGuid, newProps)
    );
    const enableSave = yield select(selectors.getEnableSave, fieldGuid);
    if (enableSave) {
        yield put(setFieldsBackgroundOnlyBatch(false));
    }
};

export const onUpdateEventSummaryImportedStatus = function* (action) {
    const { fieldGuid, agEventGeneralGuid, importedStatus } = action.payload;
    let newProps: Partial<models.AgEventSummary> = { importedStatus };
    if (importedStatus === StatusCodes.ProcessingSurfaces) {
        const fieldEventSummaries: AgEventAPI.IAgEventSummary[] = yield call(
            AgEventAPI.fetchSummaries,
            [fieldGuid]
        );
        const newEventSummaryProps = fieldEventSummaries.find(
            (s) => s.agEventGeneralGuid === agEventGeneralGuid
        );
        newProps = {
            importedStatus,
            ...newEventSummaryProps,
        };
    }
    yield put(actions.updateEventSummaryItem(fieldGuid, agEventGeneralGuid, newProps));
};

export const onUpdateBatchDepthConfig = function* (
    action: ReturnType<typeof actions.updateBatchDepthConfig>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { depthIAGuid, samplePointDepths } = action.payload;
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const fieldGuidList = [...fieldGuidToEventDetails.keys()].filter(
        (fieldGuid) => fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID
    );

    for (const fieldGuid of fieldGuidList) {
        const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
        const agEventModel = getSampleAgEventFromEventDetails(eventDetails);

        const newSamplePoints = agEventModel.samplePoints.map((samplePoint) => {
            return {
                ...samplePoint,
                samplePointDepths,
            };
        });
        yield put(
            actions.updateSamplingAgEventModel(fieldGuid, {
                depthIAGuid,
                samplePoints: newSamplePoints,
            })
        );
    }
};

export const onUpdateBatchSamplingAgEvents = function* (
    action: ReturnType<typeof actions.updateBatchSamplingEvents>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { isPointProps, newProps } = action.payload;
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const fieldGuidList = [...fieldGuidToEventDetails.keys()].filter(
        (fieldGuid) => fieldGuid !== commonModels.BATCH_TEMPLATE_FIELD_GUID
    );

    for (const fieldGuid of fieldGuidList) {
        if (isPointProps) {
            const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
            const agEventModel = getSampleAgEventFromEventDetails(eventDetails);
            const newSamplePoints = agEventModel.samplePoints.map((samplePoint) => {
                return {
                    ...samplePoint,
                    ...newProps,
                };
            });
            yield put(
                actions.updateSamplingAgEventModel(fieldGuid, {
                    samplePoints: newSamplePoints,
                })
            );
        } else {
            yield put(actions.updateSamplingAgEventModel(fieldGuid, newProps));
        }
    }
};

export const onUpdateSamplingAgEventModel = function* (
    action: ReturnType<typeof actions.updateSamplingAgEventModel>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid, newProps } = action.payload;
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);

    if (!eventDetails) {
        return;
    }

    // sampling events cannot be aggregate events
    console.assert(eventDetails.agEventTypeList.length === 1);
    const agEventTypeInfo = eventDetails.agEventTypeList[0];

    console.assert(eventDetails.eventAreaList.length > 0);
    const area = eventDetails.eventAreaList[0];
    console.assert(area.agEventList.length > 0);
    const { agEventModel } = area.agEventList[0];
    console.assert(agEventModel.sampleTypeGuid === agEventTypeInfo.sampleTypeGuid);

    yield put(
        actions.updateAgEventModel(
            fieldGuid,
            area.eventAreaId,
            agEventTypeInfo.agEventTransactionTypeGuid,
            newProps
        )
    );
};

export const onUpdatePlantingEventModel = function* (
    action: ReturnType<typeof actions.updatePlantingEventModel>
): Generator<SelectEffect | PutEffect<any>, void, any> {
    const { fieldGuid, transactionTypeGuid, newProps } = action.payload;
    const { fieldGuidToEventDetails } = yield select(selectors.getModuleState);
    const eventDetails = fieldGuidToEventDetails.get(fieldGuid);
    eventDetails.eventAreaList.forEach((agEventArea) => {
        agEventArea.agEventList.forEach((agEvent) => {
            const { agEventTransactionTypeGuid, agEventModel } = agEvent;
            if (
                agEventTransactionTypeGuid === transactionTypeGuid &&
                agEventModel.eventPlantingVarietyHybridList.length
            ) {
                agEventModel.eventPlantingVarietyHybridList =
                    agEventModel.eventPlantingVarietyHybridList.map((vh) => {
                        return { ...vh, ...newProps };
                    });
            }
        });
    });

    yield put(
        actions.updateEventDetails(fieldGuid, {
            eventAreaList: eventDetails.eventAreaList,
        })
    );
};

export const eventsSaga = function* (): Generator<AllEffect, void, unknown> {
    yield all([
        takeEvery(actions.ACTIVATE_EVENT, onActivateEvent),
        takeEvery(actions.ADD_AGGREGATE_EVENT, onAddAggregateEvent),
        takeLatest(actions.APPLY_YIELD_CALIBRATION, onApplyYieldCalibration),
        takeLatest(actions.BATCH_COPY_EVENTS, onBatchCopyEvents),
        takeLatest(actions.BATCH_COPY_EVENT_DETAILS, onBatchCopyEventDetails),
        takeLatest(actions.BATCH_SAMPLING_RESET, onBatchSamplingReset),
        takeEvery(actions.CALIBRATION_ROLLBACK_WARNING, onCalibrationRollbackWarning),
        takeEvery(actions.CREATE_NEW_BATCH_EVENT, onCreateNewBatchEvent),
        takeLatest(actions.CREATE_NEW_COPY_BATCH_EVENT, onCreateNewCopyBatchEvent),
        takeLatest(actions.COPY_BATCH_TEMPLATE_TO_EVENTS, onCopyBatchTemplateToEvents),
        takeLatest(actions.COPY_EVENT_DETAILS, onCopyEventDetails),
        takeEvery(actions.CREATE_NEW_CLASSIFIED_EVENT_DETAILS, onCreateNewClassifiedEvent),
        takeEvery(actions.CREATE_NEW_EVENT_DETAILS, onCreateNewEventDetails),
        takeEvery(actions.DELETE_TEMPORARY_SCOUTING_PHOTOS, onDeleteTemporaryScoutingPhotos),
        takeEvery(actions.DELETE_EVENTS, onDeleteEvents),
        takeLatest(actions.FETCH_EVENT_DETAILS, onFetchEventDetails),
        takeEvery(actions.FETCH_EVENT_DETAILS_SUCCEEDED, onFetchEventDetailsSucceeded),
        takeLatest(actions.FETCH_LAYER_NAME_TO_SURFACE_INFO_MAP, onFetchLayerNameToSurfaceInfoMap),
        takeEvery(actions.FETCH_SOIL_SAMPLE_RESULTS, onFetchSoilSampleResults),
        takeEvery(actions.FETCH_TISSUE_SAMPLE_RESULTS, onFetchTissueSampleResults),
        takeEvery(actions.FETCH_MERGE_SOIL_SAMPLE_RESULTS, onFetchMergeSoilSampleResults),
        takeLatest(actions.FETCH_YIELD_CALIBRATION, onFetchYieldCalibration),
        takeLatest(actions.REFRESH_YIELD_CALIBRATION, onRefreshYieldCalibration),
        takeLatest(actions.RESTORE_BATCH_SAMPLE_POINTS, onRestoreBatchSamplePoints),
        takeLatest(actions.INITIALIZE_EVENTS_ZONES, onInitializeEventsZones),
        takeLatest(actions.INITIALIZE_EVENTS_ZONES_SUCCEEDED, onInitializeEventsZonesSucceeded),
        takeEvery(actions.MERGE_EVENTS_SAVE, onMergeEventsSave),
        takeEvery(actions.MERGE_EVENTS_FAILED, onMergeEventsFailed),
        takeEvery(actions.RESET_CURRENT_EVENT_AREA_EVENT, onResetCurrentAgEventAreaAgEvent),
        takeEvery(
            [actions.RESET_EVENT_AREA_POLYGONS, actions.RESET_CLASSIFIED_EVENT_AREA_POLYGONS],
            onResetEventAreaPolygons
        ),
        takeEvery(actions.RESET_EVENT_AREAS, onResetEventAreas),
        takeEvery(actions.S3_PHOTO_COPY_FAILED, onS3PhotoCopyFailed),
        takeEvery(actions.S3_PHOTO_UPLOAD_FAILED, onS3PhotoUploadFailed),
        takeLatest(actions.SAVE_EVENT_DETAILS, onSaveEventDetails),
        takeEvery(actions.SET_BATCH_COPY_SAMPLE_POINTS, onSetBatchCopySamplePoints),
        takeEvery(actions.SET_EVENT_SAMPLE_POINTS, onSetEventSamplePoints),
        takeEvery(actions.SET_EVENT_ZONES_FROM_LAYER, onSetEventZonesFromLayer),
        takeEvery(actions.SET_SAMPLING_EVENT_ID, onSetSamplingEventId),
        takeEvery(loginActions.SET_USER_INFO_COMPLETE, fetchEventTypeInfo),
        takeEvery(actions.UPDATE_BATCH_DEPTH_CONFIG, onUpdateBatchDepthConfig),
        takeEvery(actions.UPDATE_BATCH_SAMPLING_EVENTS, onUpdateBatchSamplingAgEvents),
        takeEvery(
            actions.UPDATE_CURRENT_EVENT_AREA_EVENT_MODEL,
            onUpdateCurrentAgEventAreaAgEventModel
        ),
        takeEvery(actions.UPDATE_EVENT_MODEL, onUpdateEventModel),
        takeEvery(actions.UPDATE_SAMPLING_EVENT_MODEL, onUpdateSamplingAgEventModel),
        takeEvery(actions.UPDATE_PLANTING_EVENT_MODEL, onUpdatePlantingEventModel),
        takeEvery(actions.UPDATE_EVENT_SUMMARY_IMPORTED_STATUS, onUpdateEventSummaryImportedStatus),
    ]);
};
