import React, { ReactNode, MouseEvent as ReactMouseEvent, ReactElement } from "react";
import TableCell from "./table-cell";
import classnames from "classnames";
import { getGridColClassName } from "../utils";
import _ from "lodash";
import { Checkbox } from "~/core";

import { PencilIcon, TrashcanIcon } from "~/core/icons";
import { ITableService, IRecord } from "../../interfaces";

export interface ITableRowProps {
    isCheckbox?: boolean;
    isEditable?: boolean;
    className?: string;
    record?: IRecord;
    selected?: boolean;
    columns: string[];
    onRowSelection?: (event: ReactMouseEvent, record: IRecord) => void;
    onItemSelection?: (record: IRecord, value: boolean) => void;
    checked?: boolean;
    service: ITableService;
    openAddEditPanel?: (
        modelName: string,
        option: string,
        recordGuid: IRecord | IRecord[],
        recordName: unknown
    ) => void;
    deleteSelected?: (selectedRows: IRecord | IRecord[], deleteFromRow?: boolean) => void;
    leftContent?: Record<string, unknown>[];
}

const TableRow = ({
    isCheckbox,
    isEditable,
    className,
    leftContent,
    record,
    selected,
    columns,
    onRowSelection,
    service,
    onItemSelection,
    checked,
    openAddEditPanel,
    deleteSelected,
}: ITableRowProps): ReactElement => {
    const getCells = (record?: IRecord): ReactNode => {
        return _.map(columns as string[], (attr) => {
            if (service.getVisible(attr) === false) {
                return false;
            }
            const rowValueClassName = service.getClassName(attr);
            const className = classnames(
                getGridColClassName(service.getGridColSize(attr)),
                rowValueClassName
            );
            const value = service.printLabelValue(record, attr);
            const title = service.getTitleValue ? service.getTitleValue(record, attr) : value;
            return (
                <TableCell key={`row-${attr}`} className={className} title={`${title}`}>
                    <p>{value}</p>
                </TableCell>
            );
        });
    };
    // action seems to be a different function depending on table and action and will not allow unknown type.
    const onIconClick = (event: ReactMouseEvent, action: any, params = []): void => {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
        action(...params);
    };

    const getLeftContent = (): ReactNode => {
        return (
            leftContent &&
            leftContent.map(({ action, component }, index) => {
                return (
                    <TableCell
                        key={`left-content-${index}`}
                        className="data-table-container row-left-content"
                        onClick={(event) => {
                            onIconClick(event, action);
                        }}
                    >
                        {component}
                    </TableCell>
                );
            })
        );
    };

    const renderEditDeleteIcons = (): ReactNode => {
        const recordGuid = service.getDefaultGuid(record);
        let guid;
        if (typeof recordGuid === "object") {
            guid = recordGuid;
            if (guid == null) {
                //if a 'guid' prop wasn't found, send the entire object
                guid = recordGuid;
            }
        } else {
            guid = recordGuid;
        }

        return (
            <div className={"data-table-icon-box"}>
                {record.canDelete && (
                    <div
                        onClick={(event) => {
                            onIconClick(event, deleteSelected, [[guid], true]);
                        }}
                    >
                        <TrashcanIcon />
                    </div>
                )}
                {record.doNotEdit != null && record.doNotEdit ? null : (
                    <div
                        onClick={() =>
                            openAddEditPanel(
                                service.modelName,
                                "EDIT",
                                service.getDefaultGuid(record),
                                record[service.recordName]
                            )
                        }
                    >
                        <PencilIcon />
                    </div>
                )}
            </div>
        );
    };

    const cells = getCells(record);
    const selectedClass = selected ? "data-table-row-selected" : "";
    const plainRowClass = !isEditable && !isCheckbox ? "data-table-plain-row" : "";
    const classNames = classnames("data-table-row", className, selectedClass, plainRowClass);
    return (
        <div
            className={classNames}
            onClick={(event) => isEditable && onRowSelection(event, record)}
        >
            {getLeftContent()}
            {isEditable && (
                <TableCell
                    key={"isEditable"}
                    className={classnames("data-table-container", {
                        "no-arrow": !leftContent,
                    })}
                >
                    {selected && renderEditDeleteIcons()}
                </TableCell>
            )}
            {isCheckbox && (
                <TableCell
                    key={"isCheckbox"}
                    className={"data-table-container data-table-checkbox"}
                >
                    <Checkbox
                        value={checked || false}
                        onChange={(event, value) => {
                            onIconClick(event, onItemSelection, [record, value]);
                        }}
                    />
                </TableCell>
            )}
            {cells}
        </div>
    );
};

export default TableRow;
