import React, { useRef } from "react";
import { injectIntl, intlShape } from "react-intl";
import PropTypes from "prop-types";

import { ReportAPI } from "@ai360/core";

import { DateInput, Section, SelectInput, SubSection } from "~/core";
import CroppingSeason from "~/reports/components/inputs/cropping-season";
import { selectors as picklistSelectors, picklistNames } from "~/core/picklist";
import { messages } from "~/reports/i18n-messages";
import { model } from "~/reports/data";
import { getReportDataByReportType, getCropOptionsData } from "~/reports/data/selectors";
import { getSelectedEventGuidSet } from "~/action-panel/components/event-module/components/event-list/selectors";

import { FieldAPI } from "@ai360/core";
import { useAppSelector } from "~/store/hooks";
import { IOption, ReportOptionsComponent, ReportOptionsComponentProps } from ".";
import { getEventGeneralGuidToEventSummaryMap } from "~/recs-events/events/selectors";

type SkyHarvestSummaryProps = {
    selectedCrop: FieldAPI.ICrop | null;
};

function SkyHarvestSummary({
    intl: { formatMessage },
    onInputChange,
    selectedCrop,
}: ReportOptionsComponentProps & SkyHarvestSummaryProps) {
    const reportData = useAppSelector(getReportDataByReportType(SkyHarvestSummary.reportName));
    const crops = useAppSelector(getCropOptionsData);
    const agEventSummaryMap = useAppSelector(getEventGeneralGuidToEventSummaryMap);
    const seasons = useAppSelector((state) =>
        picklistSelectors.getPicklistOptionsFromCode(
            state,
            picklistNames.getPickListCode(picklistNames.PICKLIST_CROPPING_SEASON)
        )
    );

    const selectedEventGuidList = useAppSelector(getSelectedEventGuidSet);
    const numberOfSeasons: IOption[] = [...Array(20)].map((e, i) => {
        return { label: (i + 1).toString(), value: (i + 1).toString() };
    });
    const displayTypeOptions: IOption[] = [
        {
            label: formatMessage(messages.coverage),
            value: "coverage",
        },
        {
            label: formatMessage(messages.polygon),
            value: "polygon",
        },
    ];
    const seasonsToDisplay = useRef<string[]>();

    React.useEffect(() => {
        onInputChange(model.PROPS_SELECTED_EVENT_GUID_LIST, [...selectedEventGuidList]);

        if (selectedEventGuidList.size > 0) {
            const seasonGuids = [];

            for (const eventGeneralGuid of selectedEventGuidList) {
                const event = agEventSummaryMap.get(eventGeneralGuid);
                if (event.agEventTypeName === "Harvest") {
                    seasonGuids.push(event.croppingSeasonGuid);
                }
            }
            const seasonsText = [];
            for (const seasonGuid of seasonGuids) {
                const selectedSeason = seasons.find((season) => season.value === seasonGuid);
                if (seasonsText.indexOf(selectedSeason.label) === -1) {
                    seasonsText.push(selectedSeason.label);
                }
            }
            seasonGuids.sort();
            seasonsToDisplay.current = seasonsText;
        } else {
            seasonsToDisplay.current = [];
        }
    }, [selectedEventGuidList.size]);

    const optionsDisabled = selectedEventGuidList.size > 0;
    const croppingSeasonDisabled =
        reportData[model.PROPS_START_DATE] ||
        reportData[model.PROPS_END_DATE] ||
        selectedEventGuidList.size > 0;

    return (
        <Section className="report-options">
            <SubSection>
                <CroppingSeason
                    disabled={croppingSeasonDisabled}
                    required={!croppingSeasonDisabled}
                    reportType={SkyHarvestSummary.reportName}
                    valuesToDisplay={seasonsToDisplay.current}
                />
                <SelectInput<FieldAPI.ICrop>
                    disabled={optionsDisabled}
                    placeholderText={formatMessage(messages.crop)}
                    options={optionsDisabled ? [] : crops}
                    onChange={(value) => {
                        onInputChange(model.PROPS_CROP_GUID, value ? value.cropGuid : null);
                    }}
                    value={optionsDisabled ? null : (selectedCrop as FieldAPI.ICrop)}
                />
            </SubSection>
            <SubSection>
                <DateInput
                    disabled={optionsDisabled}
                    placeholderText={formatMessage(messages.startDate)}
                    onChange={(value) =>
                        onInputChange(model.PROPS_START_DATE, value ? value : null)
                    }
                    timeFormat={false}
                    value={optionsDisabled ? null : reportData[model.PROPS_START_DATE]}
                />
                <DateInput
                    disabled={optionsDisabled}
                    placeholderText={formatMessage(messages.endDate)}
                    onChange={(value) => onInputChange(model.PROPS_END_DATE, value ? value : null)}
                    timeFormat={false}
                    value={optionsDisabled ? null : reportData[model.PROPS_END_DATE]}
                />
            </SubSection>
            <SubSection className="two-report-options">
                <SelectInput
                    clearable={false}
                    disabled={optionsDisabled}
                    containerClassNames={["display-options-list"]}
                    placeholderText={formatMessage(messages.numberOfSeasons)}
                    options={numberOfSeasons}
                    onChange={(value) => {
                        onInputChange(model.PROPS_NUMBER_OF_SEASONS, value);
                    }}
                    value={reportData[model.PROPS_NUMBER_OF_SEASONS]}
                />
                <SelectInput
                    required
                    clearable={false}
                    containerClassNames={["display-options-list"]}
                    placeholderText={formatMessage(messages.displayType)}
                    options={displayTypeOptions}
                    onChange={(value) => {
                        onInputChange(model.PROPS_DISPLAY_TYPE, value);
                    }}
                    value={reportData[model.PROPS_DISPLAY_TYPE]}
                />
            </SubSection>
        </Section>
    );
}

SkyHarvestSummary.propTypes = {
    intl: intlShape,
    reportPreferences: PropTypes.object,
    selectedCrop: null,
};

SkyHarvestSummary.reportPreferences = [model.PROPS_DISPLAY_TYPE];

SkyHarvestSummary.defaultReportOptions = {
    [model.PROPS_CROP_GUID]: null,
    [model.PROPS_SELECTED_EVENT_GUID_LIST]: [],
    [model.PROPS_DISPLAY_TYPE]: "coverage",
    [model.PROPS_START_DATE]: null,
    [model.PROPS_END_DATE]: null,
    [model.PROPS_CROPPING_SEASON_GUID_LIST]: [],
    [model.PROPS_NUMBER_OF_SEASONS]: "3",
};

SkyHarvestSummary.reportName = ReportAPI.ReportNames.AGVANCE_SKY_HARVEST_SUMMARY;

export default injectIntl(SkyHarvestSummary) as ReportOptionsComponent<
    ReportOptionsComponentProps & SkyHarvestSummaryProps
>;
